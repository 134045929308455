<template>
  <div>
    <div>
      <strong>{{ getPrice() }}</strong>
    </div>
    <div class="ui-type-caption text-gray-600">
      {{ getPriceInfo() }}
    </div>
  </div>
</template>

<script>
import get from "lodash/get";
import { mapGetters } from "vuex";
import { formatPrice, getOptionLabel } from "../../../helpers/common";
import { getPriceUnitOptions } from "../../../helpers/options";

export default {
  name: "MarketCartTableVariantPrice",

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),
  },

  methods: {
    getPrice() {
      const price = get(this.data, `view.mp.ppm.price`, null);
      return price !== null
        ? formatPrice(price, this.currencySymbol).format()
        : "";
    },

    getPriceInfo() {
      const price_base_unit = get(this.data, `view.mp.price_base_unit`, null);
      if (price_base_unit) {
        const { price, unit } = price_base_unit;
        const unitOptions = getPriceUnitOptions();
        return formatPrice(
          price,
          `${this.currencySymbol} / ${getOptionLabel(unit, unitOptions)}`
        ).format();
      }
      return "";
    },
  },
};
</script>
